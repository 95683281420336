<template>
  <base-section id="news">
    <base-section-heading
      color="grey lighten-2"      
      title="Статьи и новости"
    />

    <v-container>
      <v-responsive
        class="mx-auto align-center text-center"
        max-width="800"
      >
        
      </v-responsive>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    data: () => ({
      
    }),
  }
</script>
